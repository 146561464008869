<template>
  <v-container>
      <v-card>
          <v-card-text class="pt-5"> 
              <v-text-field label="Titre" outlined hide-details v-model="blog.title" class="mx-4"></v-text-field>
            <v-file-input prepend-icon="mdi-camera" accept="image/*" label="Image principale" class="mx-4" v-model="main_image_file"></v-file-input>
                  
              <v-list class="mt-0">
                <v-list-item v-for="(section,i) in blog.sections" :key="section.id">
                     
                      <v-list-item-content>
                          <v-row class="mb-6 mt-5"> <v-divider></v-divider></v-row>
                        <v-row class="ma-0">
                             <v-select
                             hide-details
                        :items="section_categories"
                        label="Type de section"
                        outlined
                        v-model="section.category"
                        @change="(e)=>changedSection(section)"
                        ></v-select>
                        </v-row>  
                       <v-row class="ma-0 mt-3">
                            <v-text-field hide-details v-if="section.category=='Soustitre'" label="Soustitre" v-model="section.text"></v-text-field>
                            <v-file-input v-if="section.category=='Image'" @change="()=>deleteImage(section)" prepend-icon="mdi-camera" accept="image/*" label="Image" v-model="section.image_file"></v-file-input>
                            <v-text-field hide-details  v-if="section.category=='Image'" label="Legende" v-model="section.legend"></v-text-field>
                            <v-textarea v-if="section.category=='Paragraphe'" auto-grow label="Paragraphe" v-model="section.text"></v-textarea>
                            <v-text-field v-if="section.category=='Alinea'" label="Alinea" v-model="section.text"></v-text-field>
                            <v-textarea v-if="section.category=='Italique'" auto-grow label="Italique" v-model="section.text"></v-textarea>
                            <v-textarea v-if="section.category=='Gras'" auto-grow label="Gras" v-model="section.text"></v-textarea>

                      </v-row>
                        </v-list-item-content>
                      <v-list-item-action>
                          <Menu class="pt-3"
                          :up="()=>{if(i>0){blog.sections.splice(i,1);blog.sections.splice(i-1,0,section)}}"
                          :delete_section="()=>{changedSection(section);blog.sections.splice(i,1)}"
                          :down="()=>{if(i<blog.sections.length-1){blog.sections.splice(i,1);blog.sections.splice(i+1,0,section)}}"
                          :add="()=>{blog.sections.splice(i+1,0,{category:'Soustitre',text:'',id:Math.round(Math.random()*1000)})}"
                        /></v-list-item-action>
                      
                  </v-list-item>

              </v-list>
          </v-card-text>
          <v-card-actions><v-btn text @click="saveBlog">Ajouter article</v-btn></v-card-actions>
      </v-card>
  </v-container>
</template>

<script>
import {ref, deleteObject, uploadBytes, getDownloadURL} from 'firebase/storage'
import {doc, collection, setDoc, updateDoc, getDoc} from 'firebase/firestore'
import {firestore, storage, compression} from '../main'
import {Blog} from '../classes'
import Menu from '../components/Reordering.vue'

export default {
    components:{Menu},
    data(){
        return {
            blog: new Blog(),
            section_categories:["Soustitre","Image", "Paragraphe","Alinea","Italique","Gras"],
            main_image_file:null,
        }
    },
    methods:{
        changedSection(section) {
            if(section.image_url && section.image_url.length>0) {
                deleteObject(ref(storage, section.image_url))
            }
            section.image_url = "";
            section.text = "";
            section.legend = "";
        },
        deleteImage(section) {
            if (section.image_url && section.image_url.length > 0) {
                deleteObject(ref(storage,section.image_url));
                section.image_url = "";
            }
        },
        async saveBlog() {
            let doc_ref;
            if(this.$route.name == "Nouveau Blog") {
                doc_ref = doc(collection(firestore,"blogs"))
            } else {
                doc_ref = doc(firestore,"blogs",this.$route.params.id)
            }
            if (this.blog.image_url && this.main_image_file) {
                await deleteObject(ref(storage,this.blog.image_url))
                await deleteObject(ref(storage,this.blog.thumbnail_url))
            }
            if (this.main_image_file) {
                let data = await compression(this.main_image_file,2500,0.9);
                let thumbnail = await compression(this.main_image_file,600,0.9);
                let random = Math.round(Math.random()*1000)
                let ref_file = ref(
                storage,
                `blogs/${doc_ref.id}/${random}-${
                    this.main_image_file.name
                }`
                );
                let ref_thumbnail = ref(
                storage,
                `blogs/${doc_ref.id}/thumbnail-${random}-${
                    this.main_image_file.name
                }`
                );
                await Promise.all([uploadBytes(ref_file, data),uploadBytes(ref_thumbnail, thumbnail)]);
                this.blog.image_url = await getDownloadURL(ref_file);
                this.blog.thumbnail_url = await getDownloadURL(ref_thumbnail);
            }
            for (let section of this.blog.sections) {
                if (section.category == "Image" && section.image_file) {
                    let data = await compression(section.image_file,2000,0.9);
                    let ref_file = ref(
                    storage,
                    `blogs/${doc_ref.id}/${Math.round(Math.random()*1000)}-${
                        section.image_file.name
                    }`
                    );
                    await uploadBytes(ref_file, data)
                    section.image_url = await getDownloadURL(ref_file);
                }
                if (section.image_file)
                    delete section.image_file
            }
            if(this.$route.name == "Nouveau Blog") {
                await setDoc(doc_ref,this.blog.toObject())
            } else {
                await updateDoc(doc_ref,this.blog.toObject()) 
            }
            this.$router.push(`/blog/${doc_ref.id}`);
        }
    },
    async created() {
        if (this.$route.name != "Nouveau Blog") {
            let data_blog = (await getDoc(doc(firestore,"blogs",this.$route.params.id))).data();
            this.blog.fromObject(data_blog,this.$route.params.id);
        }
    }
}
</script>

<style>

</style>