<template>
    <v-container class="px-0">
        <v-row class="ma-0" wrap>
            <v-col class="col-12 col-md-6" v-for="blog in blogs" :key="blog.title">
                <v-card :to="`/blog/${blog.firestore_id}`">
                    <div style="overflow:hidden;position:relative" v-if="blog.thumbnail_url">
                        <v-img :src="blog.thumbnail_url" aspect-ratio="3"></v-img>
                    </div>
                    <v-card-title style="word-break:normal;">{{blog.title}}</v-card-title>
                    <v-card-subtitle>{{dateFrance(new Date(blog.date.seconds*1000))}}</v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="admin">
            <v-btn text @click="newArticle">Ajouter un article</v-btn>
        </v-row>
    </v-container>
</template>

<script>
import {auth, firestore} from '../main'
import {dateFrance} from '../classes'
import {getDocs, collection} from 'firebase/firestore'
export default {
    data(){
        return {admin:false,
        blogs:[]}
    },
    methods:{
        dateFrance:dateFrance,
        newArticle(){this.$router.push('/blog/nouveau')}
    },
    async created(){
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
        this.blogs = (await getDocs(collection(firestore,"blogs"))).docs.map(doc=>{return {...doc.data(), firestore_id:doc.id}});
        this.blogs = this.blogs.sort((a,b)=>a.date.seconds-b.date.seconds);
        this.$forceUpdate();
    }
}
</script>

<style>

</style>