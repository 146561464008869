<template>
  <v-container>
      <v-dialog v-model="dialog">
          <v-card><v-card-title>Confirmez-vous vouloir supprimer cet article ?</v-card-title>
          <v-card-text><v-btn text color="red" @click="deleteArticle">Oui</v-btn>
          <v-btn text @click="dialog=false">Non</v-btn></v-card-text>
          </v-card>
      </v-dialog>
      <v-card>
          <v-img :src="blog.image_url" max-height="55vh"></v-img>
          <v-card-title class="text-md-h3 text-h4 text-center justify-center mb-2" style="align-items:center;display:flex;word-break:normal;justify-content:center">{{blog.title}}</v-card-title>
          <v-card-subtitle class="text-subtitle-1 justify-center" style="align-items:center;display:flex">{{dateFrance(blog.date)}}</v-card-subtitle>
          <v-card-text>
              <div style="width:100%" class="my-6" v-for="section in blog.sections" :key="section.id">
                  <p class="text-subtitle-1 text-justify black--text text--lighten-2" style="white-space:pre-line;" v-if="section.category=='Paragraphe'">{{section.text}}</p>
                  <v-img v-if="section.category=='Image'" :src="section.image_url" width="100%" max-height="70vh" contain></v-img>
                  <div class="text-body-2 grey--text justify-center" style="display:flex" v-if="section.category=='Image' && section.legend && section.legend.length>0">{{section.legend}}</div>
                  <div class="text-h6 black--text text-bold font-weight-medium" v-if="section.category=='Soustitre'">{{section.text}}</div>
                  <div class="text-h6 text-normal black--text font-weight-regular" v-if="section.category=='Alinea'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{section.text}}</div>
                  <div class="text-subtitle-1 text-normal black--text font-italic" v-if="section.category=='Italique'">&nbsp;&nbsp;&nbsp;&nbsp;{{section.text}}</div>
                  <div class="text-subtitle-1 text-normal black--text font-weight-bold" v-if="section.category=='Gras'">&nbsp;&nbsp;&nbsp;&nbsp;{{section.text}}</div>

              </div>
          </v-card-text>
        <div class="text-h6 ml-4">Commentaires</div>
        <v-list
          v-if="blog.comments && blog.comments.length > 0"
          dense
          class="mt-3 ml-3"
          style="max-height: 60vh; overflow-y: scroll"
        >
          <div v-for="comment in blog.comments" :key="comment.text">
            <v-list-item>
              <div>
                <div class="flex" style="display: flex">
                  <span class="text-decoration-underline pt-2">{{
                    comment.author
                  }} {{comment.date ? " - "+ dateHeure(comment.date) : ""}}</span>
                  <v-spacer></v-spacer
                  ><v-btn
                    class="mb-1"
                    @click="() => {blog.answer = comment;}"
                    icon
                    ><v-icon>reply</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    @click="() => deleteComment(comment)"
                    v-if="comment.author_uid == uid"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
                <v-sheet
                  v-if="comment.response_text"
                  rounded
                  color="grey lighten-2"
                  class="py-1 my-2 pl-3 ml-3 pr-2"
                >
                  <div class="text-decoration-underline">
                    {{ comment.response_author }}
                  </div>
                  <div style="white-space:pre-line;">{{ comment.response_text }}</div>
                </v-sheet>
                <div style="white-space:pre-line;">{{ comment.text }}</div>
              </div>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <v-sheet
          v-if="blog.answer"
          color="grey lighten-2"
          rounded
          class="py-1 my-2 pl-3 ml-3 pr-2"
        >
          <div class="flex" style="display: flex">
            <span class="text-decoration-underline pt-2">{{
              blog.answer.author
            }}</span>
            <v-spacer></v-spacer
            ><v-btn
              class="mb-1"
              @click="() => {blog.answer = null;}"
              icon
              ><v-icon>cancel</v-icon></v-btn
            >
          </div>
          <div style="white-space:pre-line;">{{ blog.answer.text }}</div>
        </v-sheet>
        <v-row class="mx-3 mt-2">
          <v-textarea
            auto-grow
            rows="1"
            label="Nouveau commentaire"
            v-model="blog.new_comment"
          ></v-textarea>
          <v-btn
            :disabled="!blog.new_comment || blog.new_comment.length == 0"
            color="blue"
            icon
            class="mt-3"
            @click="() => sendMessage()"
          >
            <v-icon>send</v-icon>
          </v-btn>
        </v-row>
        <v-card-actions v-if="admin"><v-btn text @click="modify">Modifier article</v-btn><v-btn text color="red" @click="dialog=true">Supprimer l'article</v-btn></v-card-actions>
      </v-card>
  </v-container>
</template>

<script>
import {firestore, auth, storage} from '../main'
import {doc, getDoc, deleteDoc, arrayRemove, updateDoc, arrayUnion} from 'firebase/firestore'
import {deleteObject, ref} from 'firebase/storage'
import {Blog, dateFrance, dateHeure, Comment} from '../classes'

export default {
    data() {
        return { 
            blog: new Blog(),
            admin:false,
            dialog:false,
            uid:auth.currentUser.uid,
        }
    },
    methods:{
        dateFrance,
        dateHeure(date) {return dateHeure(date)},
        modify(){this.$router.push(`/blog/edit/${this.$route.params.id}`)},
        async deleteArticle() {
            let promises = []
            if(this.blog.image_url && this.blog.image_url.length>0) {
                promises.push(deleteObject(ref(storage,this.blog.image_url)));
            }
            if(this.blog.thumbnail_url && this.blog.thumbnail_url.length>0) {
                promises.push(deleteObject(ref(storage,this.blog.thumbnail_url)));
            }
            for (let section of this.blog.sections) {
                if(section.category=="Image" && section.image_url && section.image_url.length>0)
                   promises.push(deleteObject(ref(storage,section.image_url)));
            }
            promises.push(deleteDoc(doc(firestore,"blogs",this.$route.params.id)));
            await Promise.all(promises);
            this.$router.replace('/blogs');
        },
        sendMessage() {
            let blog_ref = doc(firestore,
                "blogs",
                this.blog.firestore_id
            );
            let new_comment = {
                text: this.blog.new_comment,
                author: auth.currentUser.displayName,
                author_uid: auth.currentUser.uid,
                date:new Date()
            };
            if (this.blog.answer) {
                new_comment.response_text = this.blog.answer.text;
                new_comment.response_author = this.blog.answer.author;
            }
            updateDoc(blog_ref, { comments: arrayUnion(new_comment) });
            this.blog.answer = null;
            this.blog.new_comment = "";
            this.blog.comments.push(
                new Comment(
                new_comment.text,
                new_comment.author,
                new_comment.author_uid,
                new_comment.date,
                new_comment.response_text,
                new_comment.response_author
                ).toObject()
            );
            console.log(this.blog.comments)
            this.$forceUpdate();
        },
        async deleteComment(comment) {
            this.blog.comments.splice(this.blog.comments.indexOf(comment), 1);
            await updateDoc(
                doc(
                firestore,
                "blogs",
                this.blog.firestore_id,
                ),
                { comments: arrayRemove(comment.toObject()) }
            );
        },

    },
    async created() {
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
        let data_doc = (await getDoc(doc(firestore, "blogs",this.$route.params.id))).data()
        this.blog.fromObject(data_doc,this.$route.params.id);
    }
}
</script>

<style>

</style>